import { supportPermissions } from  '../../src/constants/accessProfile';

import { useHistory } from 'react-router-dom';

export function accessValidator (screen) {
    // let history = useHistory();
    const token = localStorage.getItem('token');
    const object = JSON.parse(token);
    object.permissions.push('cnabConverter')

    let userID = '';
    if(object.userID) {
        userID = object.userID;
    } else {
        // history.push('/');
    }

    // const validated = validate(object.userID, supportPermissions.permessions, screen)  // MOCK DE PERMISSÕES
    const validated = validate(object.userID, object.permissions, screen)


    return validated
}


const validate = (userID, permissions, screen) => {
    // OBS: verifica se a tela está nas permissões do usuário
    const screenValidate = permissions.find(permission => permission === screen)
    if(screenValidate === undefined) {
        return false;
    }

    return true
}

export function accessList () {
    const token = localStorage.getItem('token');
    const object = JSON.parse(token);

    let userID = '';
    if(object.userID) {
        userID = object.userID;
    } else {
        // history.push('/');
    }

    if(object.permissions.length === 0){
        return false;
    }

    object.permissions.push('cnabConverter')

    return object.permissions;
}