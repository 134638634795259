import React from "react";
import {
  useLocation,
} from "react-router-dom";

import Button from "../Button/";

const HeaderPage = ({ children }) => {
  const location = useLocation();

  let title = "";

  //   console.log("patch name:", location.pathname);

  switch (location.pathname) {
    case "/MonthlyBilling":
      title = "Cobranças Mensalistas";
      break;
    case "/CnabConverter":
      title = "Conversor de CNAB";
      break;
    default:
      title = "";
      break;
  }
  //   if (location.pathname === "/home") return;

  return (
    <div
      style={{
        width: "100%",
        // height: 90,
        top: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        borderBottom: "1px solid #E2E2E2",
      }}
    >
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
            <div className="col">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <p className="text-title-Appito justify-content-start pt-4 tracking-in-expand ">
                  {title}
                </p>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FooterPage = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        height: 90,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        borderTop: "1px solid #E2E2E2",
      }}
    >
      <div className="container ">
        <div className="row justify-content-center ">
          <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
            <div className="col">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function NavBar({
  children,
  headerRender,
  activeButton = false,
  hiddenFooter = false,
  textButtonFooter = "Botão footer",
  onClickButtonFooter,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <HeaderPage>
        <div>{headerRender()}</div>
      </HeaderPage>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            flex: 1,
          }}
        >
          {children}
        </div>
      </div>

      {hiddenFooter ? (
        <FooterPage>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "14px",
              }}
            >
              {activeButton ? (
                <div>
                  <Button
                    text={textButtonFooter}
                    typeButton="primary"
                    onClick={() => onClickButtonFooter()}
                    style="btn-sm col-12 scale-in-center"
                  />
                </div>
              ) : (
                <div>
                  <div
                    className="col row mt-3"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "14px",
                    }}
                  >
                    <button
                      style={{ cursor: "default" }}
                      className={`btn button-blocked-appito btn-sm col-12 scale-in-center`}
                      type="button"
                    >
                      {textButtonFooter}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </FooterPage>
      ) : (
        <div></div>
      )}
    </div>
  );
}
