import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import "../../style/global.css";
import api from "../../services/api";
import NavBar from "../../components/NavBar";
import BookingContractList from "../../components/BookingContractList";
import "./style.css";

function MonthlyBilling() {
  let history = useHistory();

  //States
  const [customLoader, setCustomLoader] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  const [monthContracts, setMonthContracts] = useState([]);
  const [bookingContracts, setBookingContracts] = useState([]);
  const [monthContract, setMonthContract] = useState();
  const [typeContracts, setTypeContracts] = useState([]);
  const [typeContract, setTypeContract] = useState();
  const [sports, setSports] = useState([]);
  const [sportId, setSportId] = useState();
  const [arenas, setArenas] = useState([]);
  const [arenaId, setArenaId] = useState();
  const [allChecked, setAllChecked] = useState(false);
  const [sendActive, setSendActive] = useState(false);

  let arenaIdSend = "";
  let sportIdSend = "";
  let typeContractSend = "";
  let monthContractSend = "";

  const getMonthContracts = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetMonthContracts`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setMonthContracts(response.data.list);
      changeMonth(response.data.list[0].date);
      monthContractSend = response.data.list[0].date;
      validateSelects();
    } catch (error) {
      console.error(error);

      handleClickLoader();
      toast.error("Erro ao buscar meses");
    }
  };

  const getTypeContracts = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetTypeContracts`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setTypeContracts(response.data.list);
      changeTypeContract(response.data.list[0].id);
      typeContractSend = response.data.list[0].id;
      validateSelects();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao buscar tipos de contratos");

      handleClickLoader();
    }
  };

  const getArenaContracts = async () => {
    try {
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const response = await api.get(`api/suporte/GetArenaContracts`, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      setArenas(response.data.list);
      setArenaId(response.data.list[0].id);
      setSports(response.data.list[0].sports);
      setSportId(response.data.list[0].sports[0].sportId);
      arenaIdSend = response.data.list[0].id;
      sportIdSend = response.data.list[0].sports[0].sportId;
      validateSelects();
    } catch (error) {
      console.error(error);

      handleClickLoader();
      toast.error("Erro ao buscar arenas");
    }
  };

  const onClickButtonFooter = async () => {
    try {
      handleClickLoader(true);
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      const contracts = bookingContracts
        .filter((contract) => contract.picked === true)
        .map((contract) => ({
          bookingId: contract.bookingId,
          contractId: contract.contractId,
          isB2B: contract.isB2B,
        }));

      const data = {
        type: typeContract,
        contracts: contracts,
      };

      const response = await api.post(`api/suporte/SendBilling`, data, {
        headers: { Authorization: "Bearer " + accessToken },
        timeout: 600000, // 10 minutos em milissegundos
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }
      if (response.data.list.length > 0) {
        response.data.list.map((response) => {
          if (response.toastError) {
            toast.error(response.message);
          } else {
            toast.success(response.message);
          }
        });
      }

      getBookingContracts(); // ATUALIZANDO LISTA

      handleClickLoader();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao enviar cobranças");

      getBookingContracts(); // ATUALIZANDO LISTA

      handleClickLoader();
    }
  };

  const changeArena = (arenaId) => {
    arenas.map((arena) => {
      if (arenaId == arena.id) {
        setSports(arena.sports);
        setSportId(arena.sports[0].sportId);
        sportIdSend = arena.sports[0].sportId;
      }
    });
    setArenaId(arenaId);

    arenaIdSend = arenaId;
    validateSelects();
  };

  const changeMonth = (date) => {
    setMonthContract(date);
    monthContractSend = date;
    validateSelects();
  };

  const changeTypeContract = (type) => {
    setTypeContract(type);
    typeContractSend = type;
    validateSelects();
  };

  const changeSport = (sportId) => {
    setSportId(sportId);
    sportIdSend = sportId;
    validateSelects();
  };

  const validateSelects = async () => {
    if (arenaIdSend && sportIdSend && monthContractSend && typeContractSend) {
      getBookingContracts(); // carregamento de tela
      ShowHeader(true);
    }

    if (arenaId && sportId && monthContract && typeContract) {
      getBookingContracts(); // alteração dos checks
    }
  };

  const getBookingContracts = async () => {
    try {
      setCustomLoader(true);
      const token = localStorage.getItem("token");
      const object = JSON.parse(token);

      let accessToken = "";
      if (object.accessToken) {
        accessToken = object.accessToken;
      }

      setBookingContracts([]); // limpando state
      setAllChecked(false); // desabilitando check pai
      setSendActive(false); // desabilitando botão de cobrança

      const data = {
        arenaID: arenaIdSend || arenaId,
        sportID: sportIdSend || sportId,
        date: monthContractSend || monthContract, //"2023-11-01", //
        type: typeContractSend || typeContract,
      };

      const response = await api.post(`api/suporte/GetBookingContracts`, data, {
        headers: { Authorization: "Bearer " + accessToken },
      });

      if (response.data.error === true) {
        toast.error(response.data.message);

        handleClickLoader();
        return;
      }

      const listPicked = response.data.list.map((item) => ({
        ...item,
        picked: false,
      }));

      setBookingContracts(listPicked);
      setCustomLoader();
    } catch (error) {
      console.error(error);
      toast.error("Erro ao contratos");

      handleClickLoader();
    }
  };

  const headerRender = () => {
    return (
      <>
        {showHeader ? (
          <div className="show-header">
            <div className="row mb-3">
              <div className="arena-list">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control form-select scale-in-center BCS-select`}
                    placeholder="Arenas"
                    value={arenaId}
                    onChange={(event) => changeArena(event.target.value)}
                  >
                    {arenas.map((arena, index) => (
                      <option key={index} value={arena.id}>
                        {arena.shortName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="sport-list">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control  form-select scale-in-center BCS-select`}
                    placeholder="Tipos"
                    value={sportId}
                    onChange={(event) => changeSport(event.target.value)}
                  >
                    {sports.map((sport, index) => (
                      <option key={index} value={sport.sportId}>
                        {sport.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="contract-list">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control  form-select scale-in-center BCS-select`}
                    placeholder="Tipos"
                    value={typeContract}
                    onChange={(event) => changeTypeContract(event.target.value)}
                  >
                    {typeContracts.map((type, index) => (
                      <option key={index} value={type.id}>
                        {type.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="month-list">
                <div className="col">
                  <select
                    type="text"
                    className={`form-control  form-select scale-in-center BCS-select`}
                    placeholder="Mês/ano"
                    value={monthContract}
                    onChange={(event) => changeMonth(event.target.value)}
                  >
                    {monthContracts.map((month, index) => (
                      <option key={index} value={month.date}>
                        {month.dateDesc}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* TITULO da listagem */}
            <div className="header-list">
              <div className="header-checked">
                <input
                  style={{
                    backgroundColor: allChecked ? "#173016" : "",
                    border: "2px solid #494C45",
                    borderRadius: "2px",
                  }}
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="defaultCheck1"
                  checked={allChecked}
                  onChange={() => clickAllChecked()}
                />
              </div>
              <div className="name-column">Nome</div>
              <div className="default-column-14">
                <div className="text-truncate">Dia/horário</div>
              </div>
              <div className="default-column-14">Valor</div>
              <div className="default-column-12">Anterior</div>
              <div className="default-column-18">Atual</div>
            </div>

            <div
              className={customLoader === true ? "BCS-divisor-loader mt-4" : ""}
            />
          </div>
        ) : (
          <div className="row">
            <div className={"BCS-divisor-loader mt-4"} />
          </div>
        )}
      </>
    );
  };

  const clickAllChecked = () => {
    const actualState = !allChecked;
    const updatedContracts = [...bookingContracts];

    setAllChecked(actualState);

    updatedContracts.map((contract) => {
      contract.picked = actualState;
    });

    setBookingContracts(updatedContracts);

    if (actualState === true) {
      setSendActive(true);
    } else {
      setSendActive(false);
    }
  };

  const clickChecked = (contractId) => {
    const updatedContracts = [...bookingContracts];

    const contractToUpdate = updatedContracts.find(
      (contract) => contract.contractId === contractId
    );

    if (contractToUpdate) {
      contractToUpdate.picked = !contractToUpdate.picked;

      setBookingContracts(updatedContracts);
    }

    if (contractToUpdate.picked === true) {
      setSendActive(true);
    } else {
      setSendActive(false);
    }

    const haspickedContractChield = updatedContracts.some(
      (contract) => contract.picked
    );
    if (haspickedContractChield) {
      setSendActive(true); // caso algum filho esteja picked true, mantem true
    } else {
      setSendActive(false); // caso todos os filhos estejam picked false, mantem false
    }

    const hasUnpickedContract = updatedContracts.some(
      (contract) => !contract.picked
    );
    if (hasUnpickedContract) {
      setAllChecked(false); // caso tenha um click igual a false, desmarca o check pai
    } else {
      setAllChecked(true); // caso clique em todos os filhos, marca o check pai
    }
  };

  const handleClickLoader = (loader = false) => {
    setCustomLoader(loader);
  };

  const ShowHeader = (bool = false) => {
    return setShowHeader(bool);
  };
  useEffect(() => {
    getMonthContracts();
    getTypeContracts();
    getArenaContracts();
  }, []);

  return (
    <NavBar
      headerRender={headerRender}
      activeButton={sendActive}
      hiddenFooter={showHeader}
      textButtonFooter={"Enviar cobrança"}
      onClickButtonFooter={onClickButtonFooter}
    >
      <section>
        <div className="container ">
          <div className="row justify-content-center ">
            <div className="col-12 col-md-12 col-lg-10 col-xl-10 text-center ">
              <div className="mt-2">
                {bookingContracts.map((bookingContract) => (
                  <BookingContractList
                    key={bookingContract.contractId}
                    contract={bookingContract}
                    onClickCheck={() =>
                      clickChecked(bookingContract.contractId)
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </NavBar>
  );
}

export default MonthlyBilling;
