import React, { useState, useEffect } from 'react';

// mensagens de erro
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import '../../style/global.css';
import Button from '../../components/Button/';
import mask from '../../util/mask';
import logo from '../../assets/images/appito/copa_capa_desk.jpg';

import { accessValidator } from '../../util/validationProfile';

function Copa() {
  let history = useHistory();

  //States
  const [name, setName] = useState('');
  const [document, setDocument] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [category, setCategory] = useState('');

  function handleCancel() {
    history.push('/home');
  }

  const removeCharsPhone = (characteres) => {
    let newPhone = mask.maskPhone(characteres);
    setPhone(newPhone);
  };

  const removeCharsDocument = (characteres) => {
    let newCPF = mask.maskCPF(characteres);
    setDocument(newCPF);
  };

  async function handleClickRegister() {
    const token = localStorage.getItem('token');

    const data = {
      name,
      document,
      email,
      phone,
      category
    };


    try {
      // await api.post('/entrepreneur', data, {
      //   headers: { 'x-access-token': token },
      // });

      // toast.success('Registrado com sucesso.');
      // TODO: fazer registro no GCP - falar com a Dani

      toast.warn('Opa.. ainda estamos trabalhando nessa funcionalidade');

    } catch (error) {
      toast.error('Erro ao registrar');
    }
  }

  const validate = () => {
    const verify = accessValidator('copa');

    if(verify === false) {
      toast.info("Seu usuário não tem permissões para esse sistema, contate o suporte")
      history.push('/home');
    }
  }

  useEffect(() => {
    validate();
  }, []);

  return (
    <section>
      <div className='container '>
        <div className='row justify-content-center '>
          <div className='col-12 col-md-12 col-lg-12 col-xl-6 text-center '>
            {/* <div className='col'>
              <p className='text-title-Appito justify-content-center pt-4 tracking-in-expand '>
                Mundo Appito
              </p>
            </div> */}

            <div
              // style={{ backgroundColor: '#000' }}
            >
              <img src={logo} alt='logo' width={300}  />
            </div>

            <div className='row mt-3'>
              <small id='nome' className='form-text text-muted'>
                Nome
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito scale-in-center '
                  placeholder='Nome'
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <small id='document' className='form-text text-muted'>
                CPF
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito scale-in-center '
                  placeholder='CPF'
                  value={document}
                  onChange={(event) => removeCharsDocument(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <small id='email' className='form-text text-muted'>
                Email
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito  scale-in-center'
                  placeholder='Email'
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </div>
            </div>

            <div className='row mt-3'>
              <small id='Celular' className='form-text text-muted'>
                Celular
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito  scale-in-center'
                  placeholder='Celular'
                  value={phone}
                  onChange={(event) => removeCharsPhone(event.target.value)}
                />
              </div>
            </div>


            <div className='row mt-3'>
              <small id='Categoria' className='form-text text-muted'>
                Categoria
              </small>
              <div className='col'>
                <input
                  type='text'
                  className='form-control input-appito  scale-in-center'
                  placeholder='Categoria'
                  value={category}
                  onChange={(event) => setCategory(event.target.value)}
                />
              </div>
            </div>

            <div>
              <Button
                text='Cadastrar'
                typeButton='primary'
                onClick={() => handleClickRegister()}
              />
            </div>

            <div className=' mb-2 mt-2 divisor-green-1' />

            <div>
              <Button
                text='Voltar'
                typeButton='secundary'
                onClick={() => handleCancel()}
                style='btn-sm col-3 mb-4 scale-in-center'
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Copa;
