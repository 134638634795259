import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// mensagens de erro
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

import "../../style/global.css";

import logo from "../../assets/images/storyset/Team spirit-pana.png";

import { accessValidator } from "../../util/validationProfile";

function Home() {
  const [allQtdProducts, setAllAtdProducts] = useState(0);
  const [allQtdSales, setAllAtdSales] = useState(0);
  const [business, setBusiness] = useState({});
  //States

  let history = useHistory();

  function handleClickMonthlyBilling() {
    history.push("/monthlyBilling");
  }

  function handleClickBilling() {
    history.push("/billing");
  }

  const getBusiness = async () => {
    const token = localStorage.getItem("token");

    try {
      // const response = await api.get('/businesses', {
      //   headers: { 'x-access-token': token },
      // });
      // setBusiness(response.data);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getSales = async () => {
    const token = localStorage.getItem("token");

    let ascdesc = -1;
    let sortby = "created_at";

    try {
      // const response = await api.get('/sale', {
      //   headers: { 'x-access-token': token },
      //   params: {
      //     ascdesc,
      //     limit: 10000,
      //     sortby,
      //     page: 1,
      //     search: '',
      //   },
      // });
      // setAllAtdSales(response.data.meta.total);
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  };

  const getProducts = async () => {
    const token = localStorage.getItem("token");

    let ascdesc = -1;
    let sortby = "created_at";

    const data = {
      ascDesc: -1,
      sortBy: "created_at",
      limit: 10,
      page: 1,
    };

    try {
      // const response = await api.get('/product', {
      //   headers: { 'x-access-token': token },
      //   params: {
      //     ascdesc,
      //     limit: 10000,
      //     sortby,
      //     page: 1,
      //     search: '',
      //   },
      // });
      // setAllAtdProducts(response.data.meta.total);
      // setNameBusiness(response.data);
    } catch (error) {
      // toast.error(error.response.data.message);
    }
  };

  const validate = () => {
    const verify = accessValidator("home");

    if (verify === false) {
      toast.info(
        "Seu usuário não tem permissões para esse sistema, contate o suporte"
      );
      history.push("/home");
    }
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      <section>
        <div className="container ">
          <div className="mt-2 col">
            <div className="col">
              <b className="text-title-Appito justify-content-center pt-2  tracking-in-expand ">
                Appito Suporte
              </b>
            </div>
            <p className="text-title-Appito justify-content-center  tracking-in-expand ">
              <img src={logo} alt="logo" width={600} />
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
